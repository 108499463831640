<template>
  <div class="practiceEnd">
    <div class="container">
      <img src="@/assets/image/other/congratulation.png" alt="" />
      <p>恭喜您完成啦！</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  methods: {},
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.replace({
        path: '/topic',
      });
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .practiceEnd {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 210px;
        height: 210px;
        margin-top: -50px;
      }
      p {
        font-size: 60px;
        line-height: 48px;
        color: #7a3398;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .practiceEnd {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 273px;
        height: 273px;
        margin-top: -65px;
      }
      p {
        font-size: 78px;
        line-height: 62px;
        color: #7a3398;
      }
    }
  }
}
</style>
